*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Inter, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: 0 0 0 1px #2563eb;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  color: #374151;
  pointer-events: none;
  border-color: inherit;
  background-color: #f3f4f6;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control {
  height: calc(3.5rem + 2px);
  padding: 1rem .75rem;
  line-height: 1.25;
}

.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  height: 100%;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: #0000;
}

.form-floating > .form-control::-moz-placeholder {
  color: #0000;
}

.form-floating > .form-control:-ms-input-placeholder {
  color: #0000;
}

.form-floating > .form-control::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.input-group > .form-control {
  width: 1%;
}

.input-group > .form-control:focus {
  z-index: 3;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.was-validated .form-control:valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus {
  z-index: 3;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.form-select.is-invalid {
  border-color: #dc3545;
}

.form-select.is-invalid:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.form-check-input.is-invalid {
  border-color: #dc3545;
}

.form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn.active, .btn.active:focus {
  box-shadow: none;
}

.fade {
  transition: opacity .15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

.dropdown-menu {
  z-index: 1000;
}

.dropdown-item.active, .dropdown-item:active {
  color: #1f2937;
  background-color: #0d6efd;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.nav-tabs .nav-link {
  color: #4b5563;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #9ca3af;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #2563eb;
  border-color: #2563eb;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  color: #4b5563;
  box-shadow: none;
  background: #f3f4f6;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background: #2563eb;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.nav-pills .disabled {
  color: #9ca3af;
  background-color: #f3f4f680;
}

.nav-pills.menu-sidebar .nav-link {
  box-shadow: none;
  background-color: #0000;
  border-radius: 0;
  padding: 0 5px;
}

.nav-pills.menu-sidebar .nav-link.active {
  color: #1266f1;
  border-left: .125rem solid #1266f1;
  font-weight: 600;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  -ms-flex-basis: 0;
  flex-basis: 0;
}

.tab-content > .active {
  display: block;
}

.navbar-collapse {
  -ms-flex-basis: 100%;
  flex-basis: 100%;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand .navbar-collapse {
  -ms-flex-basis: auto;
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  z-index: 1000;
  -ms-flex-grow: 1;
  background-color: #0000;
  border-left: 0;
  border-right: 0;
  flex-grow: 1;
  transition: none;
  bottom: 0;
  transform: none;
  visibility: visible !important;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
  color: #000000e6;
}

.navbar-light .navbar-text {
  color: #0000008c;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000000e6;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: calc(.5rem - 1px);
  border-bottom-right-radius: calc(.5rem - 1px);
}

.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.modal {
  z-index: 1055;
}

.modal-dialog {
  margin: .5rem;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-backdrop {
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-body {
  flex: auto;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.tooltip {
  z-index: 1080;
  font-family: var(--bs-font-sans-serif);
  -webkit-text-align: start;
  text-align: start;
  -webkit-text-shadow: none;
  text-shadow: none;
  -webkit-text-transform: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: 1;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: -1px;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  -webkit-text-align: center;
  text-align: center;
  background-color: #6d6d6d;
  border-radius: .25rem;
  padding: 6px 16px;
  font-size: 14px;
}

.popover {
  z-index: 1070;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  -webkit-text-align: start;
  text-align: start;
  -webkit-text-shadow: none;
  text-shadow: none;
  -webkit-text-transform: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: .5rem;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  left: calc(-.5rem - 1px);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: 1rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  right: calc(-.5rem - 1px);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  background-color: #fff;
  border-bottom: 1px solid #0003;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  margin-bottom: 0;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: 1rem;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  opacity: .5;
  background: none;
  transition: opacity .15s;
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
}

.carousel-control-prev-icon {
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-indicators {
  z-index: 2;
  margin-left: 15%;
  margin-right: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  -webkit-text-indent: -999px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.offcanvas {
  z-index: 1045;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas.show {
  transform: none;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.visually-hidden {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.animation {
  padding: auto;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fade-in {
  animation-name: _fade-in;
}

.fade-out {
  animation-name: _fade-out;
}

.animation.infinite {
  animation-iteration-count: infinite;
}

.animation.delay-1s {
  animation-delay: 1s;
}

.animation.delay-2s {
  animation-delay: 2s;
}

.animation.delay-3s {
  animation-delay: 3s;
}

.animation.delay-4s {
  animation-delay: 4s;
}

.animation.delay-5s {
  animation-delay: 5s;
}

.animation.fast {
  animation-duration: .8s;
}

.animation.faster {
  animation-duration: .5s;
}

.animation.slow {
  animation-duration: 2s;
}

.animation.slower {
  animation-duration: 3s;
}

.slide-in-left {
  animation-name: _slide-in-left;
}

.slide-in-right {
  animation-name: _slide-in-right;
}

.slide-out-left {
  animation-name: _slide-out-left;
}

.slide-out-right {
  animation-name: _slide-out-right;
}

.ripple-surface {
  vertical-align: bottom;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.ripple-surface-unbound {
  overflow: visible;
}

.ripple-wave {
  opacity: .5;
  pointer-events: none;
  touch-action: none;
  z-index: 999;
  background-image: radial-gradient(circle, #0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #0000 70%);
  border-radius: 50%;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0, 0, .15, 1), cubic-bezier(0, 0, .15, 1);
  position: absolute;
  transform: scale(0);
}

.ripple-wave.active {
  opacity: 0;
  transform: scale(1);
}

.btn .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.ripple-surface-primary .ripple-wave {
  background-image: radial-gradient(circle, #1266f133 0, #1266f14d 40%, #1266f166 50%, #1266f180 60%, #1266f100 70%);
}

.ripple-surface-secondary .ripple-wave {
  background-image: radial-gradient(circle, #b23cfd33 0, #b23cfd4d 40%, #b23cfd66 50%, #b23cfd80 60%, #b23cfd00 70%);
}

.ripple-surface-success .ripple-wave {
  background-image: radial-gradient(circle, #00b74a33 0, #00b74a4d 40%, #00b74a66 50%, #00b74a80 60%, #00b74a00 70%);
}

.ripple-surface-info .ripple-wave {
  background-image: radial-gradient(circle, #39c0ed33 0, #39c0ed4d 40%, #39c0ed66 50%, #39c0ed80 60%, #39c0ed00 70%);
}

.ripple-surface-warning .ripple-wave {
  background-image: radial-gradient(circle, #ffa90033 0, #ffa9004d 40%, #ffa90066 50%, #ffa90080 60%, #ffa90000 70%);
}

.ripple-surface-danger .ripple-wave {
  background-image: radial-gradient(circle, #f9315433 0, #f931544d 40%, #f9315466 50%, #f9315480 60%, #f9315400 70%);
}

.ripple-surface-light .ripple-wave {
  background-image: radial-gradient(circle, #fbfbfb33 0, #fbfbfb4d 40%, #fbfbfb66 50%, #fbfbfb80 60%, #fbfbfb00 70%);
}

.ripple-surface-dark .ripple-wave {
  background-image: radial-gradient(circle, #26262633 0, #2626264d 40%, #26262666 50%, #26262680 60%, #26262600 70%);
}

.ripple-surface-white .ripple-wave {
  background-image: radial-gradient(circle, #fff3 0, #ffffff4d 40%, #fff6 50%, #ffffff80 60%, #fff0 70%);
}

.ripple-surface-black .ripple-wave {
  background-image: radial-gradient(circle, #0003 0, #0000004d 40%, #0006 50%, #00000080 60%, #0000 70%);
}

.datepicker-toggle-button {
  background-color: #0000;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}

.datepicker-toggle-button:focus, .datepicker-toggle-button:hover {
  color: #2979ff;
}

.datepicker-backdrop {
  width: 100%;
  height: 100%;
  z-index: 1065;
  background-color: #0006;
  position: fixed;
  inset: 0;
}

.datepicker-dropdown-container {
  width: 328px;
  height: 380px;
  z-index: 1066;
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
}

.datepicker-modal-container {
  width: 328px;
  height: 512px;
  z-index: 1066;
  background-color: #fff;
  border-radius: .6rem .6rem .5rem .5rem;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
}

.datepicker-header {
  height: 120px;
  background-color: #2979ff;
  border-radius: .5rem .5rem 0 0;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
}

.datepicker-title {
  height: 32px;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.datepicker-title-text {
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
}

.datepicker-date {
  height: 72px;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.datepicker-date-text {
  color: #fff;
  font-size: 34px;
  font-weight: 400;
}

.datepicker-main {
  height: 100%;
  position: relative;
}

.datepicker-date-controls {
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  color: #000000a3;
  justify-content: space-between;
  padding: 10px 12px 0;
  display: flex;
}

.datepicker-view-change-button {
  color: #666;
  box-shadow: none;
  background-color: #0000;
  border: none;
  border-radius: 10px;
  margin: 0;
  padding: 10px;
  font-size: .9rem;
  font-weight: 500;
}

.datepicker-view-change-button:hover, .datepicker-view-change-button:focus {
  background-color: #eee;
}

.datepicker-view-change-button:after {
  content: "";
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top-style: solid;
  border-top-width: 5px;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  margin: 0 0 0 5px;
  display: inline-block;
}

.datepicker-arrow-controls {
  margin-top: 10px;
}

.datepicker-previous-button {
  width: 40px;
  height: 40px;
  color: #000000a3;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0 24px 0 0;
  padding: 0;
  line-height: 40px;
  position: relative;
}

.datepicker-previous-button:hover, .datepicker-previous-button:focus {
  background-color: #eee;
  border-radius: 50%;
}

.datepicker-previous-button:after {
  content: "";
  border: 0 solid;
  border-width: 2px 0 0 2px;
  margin: 15.5px;
  position: absolute;
  inset: 0;
  transform: translateX(2px)rotate(-45deg);
}

.datepicker-next-button {
  width: 40px;
  height: 40px;
  color: #000000a3;
  background-color: #0000;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  line-height: 40px;
  position: relative;
}

.datepicker-next-button:hover, .datepicker-next-button:focus {
  background-color: #eee;
  border-radius: 50%;
}

.datepicker-next-button:after {
  content: "";
  border: 2px solid;
  border-width: 2px 2px 0 0;
  margin: 15.5px;
  position: absolute;
  inset: 0;
  transform: translateX(-2px)rotate(45deg);
}

.datepicker-view {
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
}

.datepicker-table {
  width: 304px;
  margin-left: auto;
  margin-right: auto;
}

.datepicker-day-heading {
  width: 40px;
  height: 40px;
  -webkit-text-align: center;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
}

.datepicker-cell {
  -webkit-text-align: center;
  text-align: center;
}

.datepicker-cell.disabled {
  color: #ccc;
  cursor: default;
  pointer-events: none;
}

.datepicker-cell.disabled:hover {
  cursor: default;
}

.datepicker-cell:hover {
  cursor: pointer;
}

.datepicker-cell:not(.disabled):not(.selected):hover .datepicker-cell-content {
  background-color: #d3d3d3;
}

.datepicker-cell.selected .datepicker-cell-content {
  color: #fff;
  background-color: #2979ff;
}

.datepicker-cell:not(.selected).focused .datepicker-cell-content {
  background-color: #eee;
}

.datepicker-cell.focused .datepicker-cell-content.selected {
  background-color: #2979ff;
}

.datepicker-cell.current .datepicker-cell-content {
  border: 1px solid #000;
}

.datepicker-small-cell {
  width: 40px;
  height: 40px;
}

.datepicker-small-cell-content {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 36px;
}

.datepicker-large-cell {
  width: 76px;
  height: 42px;
}

.datepicker-large-cell-content {
  width: 72px;
  height: 40px;
  border-radius: 999px;
  padding: 1px 2px;
  line-height: 40px;
}

.datepicker-footer {
  height: 56px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  position: absolute;
  bottom: 0;
}

.datepicker-footer-btn {
  color: #2979ff;
  cursor: pointer;
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  height: 40px;
  letter-spacing: .1rem;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: .8rem;
  font-weight: 500;
  line-height: 40px;
}

.datepicker-footer-btn:hover, .datepicker-footer-btn:focus {
  background-color: #eee;
}

.datepicker-clear-btn {
  margin-right: auto;
}

.timepicker-wrapper {
  touch-action: none;
  z-index: 1065;
  opacity: 0;
  background-color: #0006;
  inset: 0;
}

.timepicker-elements {
  min-width: 310px;
  min-height: 325px;
  background: #fff;
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem;
}

.timepicker-head {
  height: 100px;
  background-color: #2979ff;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  padding: 10px 24px 10px 50px;
}

.timepicker-button {
  min-width: 64px;
  box-sizing: border-box;
  letter-spacing: .1rem;
  -webkit-text-transform: uppercase;
  text-transform: uppercase;
  color: #2979ff;
  height: 40px;
  background-color: #0000;
  border: none;
  border-radius: 10px;
  outline: none;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: .8rem;
  font-weight: 500;
  line-height: 40px;
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1), box-shadow .25s cubic-bezier(.4, 0, .2, 1), border .25s cubic-bezier(.4, 0, .2, 1);
}

.timepicker-button:hover {
  background-color: #00000014;
}

.timepicker-button:focus {
  background-color: #00000014;
  outline: none;
}

.timepicker-current {
  letter-spacing: -.00833em;
  color: #fff;
  opacity: .54;
  background: none;
  border: none;
  padding: 0;
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.timepicker-current.active {
  opacity: 1;
}

.timepicker-current-wrapper {
  direction: ltr;
}

.timepicker-mode-wrapper {
  color: #ffffff8a;
  margin-left: 20px;
  font-size: 18px;
}

.timepicker-mode-wrapper.active {
  opacity: 1;
}

.timepicker-clock-wrapper {
  min-width: 310px;
  max-width: 325px;
  min-height: 305px;
  height: 100%;
  overflow-x: hidden;
}

.timepicker-clock {
  width: 260px;
  height: 260px;
  cursor: default;
  background-color: #00000012;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
}

.timepicker-time-tips-minutes.active, .timepicker-time-tips-inner.active, .timepicker-time-tips-hours.active {
  color: #fff;
  background-color: #2979ff;
  font-weight: 400;
}

.timepicker-time-tips-minutes.disabled, .timepicker-time-tips-inner.disabled, .timepicker-time-tips-hours.disabled {
  color: #b3afaf;
  pointer-events: none;
  background-color: #0000;
}

.timepicker-dot {
  letter-spacing: -.00833em;
  color: #fff;
  opacity: .54;
  background: none;
  border: none;
  padding: 0;
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.timepicker-middle-dot {
  width: 6px;
  height: 6px;
  background-color: #2979ff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timepicker-hand-pointer {
  height: 40%;
  transform-origin: center bottom 0;
  width: 2px;
  background-color: #2979ff;
  bottom: 50%;
  left: calc(50% - 1px);
}

.timepicker-time-tips.active {
  color: #fff;
}

.timepicker-circle {
  width: 4px;
  height: 4px;
  box-sizing: content-box;
  border: 14px solid #2979ff;
  border-radius: 100%;
  top: -21px;
  left: -15px;
}

.timepicker-hour-mode {
  color: #fff;
  opacity: .54;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
}

.timepicker-hour, .timepicker-minute {
  cursor: pointer;
}

.timepicker-hour-mode:hover, .timepicker-hour-mode:focus, .timepicker-hour:hover, .timepicker-hour:focus, .timepicker-minute:hover, .timepicker-minute:focus {
  background-color: #00000026;
  outline: none;
}

.timepicker-hour-mode.active, .timepicker-hour.active, .timepicker-minute.active {
  color: #fff;
  opacity: 1;
}

.timepicker-footer {
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  width: 100%;
  height: 56px;
  background-color: #fff;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.timepicker-container {
  max-height: calc(100% - 64px);
  overflow-y: auto;
  box-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
}

.timepicker-icon-up.active, .timepicker-icon-down.active {
  opacity: 1;
}

.timepicker-toggle-button {
  cursor: pointer;
  background-color: #0000;
  border: none;
  outline: none;
  transition: all .3s;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
}

.timepicker-toggle-button:hover, .timepicker-toggle-button:focus, .timepicker-input:focus + .timepicker-toggle-button, .timepicker-input:focus + .timepicker-toggle-button i {
  color: #2979ff;
}

.timepicker a.timepicker-toggle-button, .timepicker-toggle-button.timepicker-icon {
  right: 1px;
}

.timepicker-modal .fade.show {
  opacity: 1;
}

.stepper {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: height .2s ease-in-out;
  position: relative;
  overflow: hidden;
}

.stepper:not(.stepper-vertical) {
  -webkit-box-pack: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
  display: flex;
}

.stepper:not(.stepper-vertical) .stepper-content {
  width: 100%;
  padding: 1rem;
  position: absolute;
}

.stepper:not(.stepper-vertical) .stepper-step {
  height: 4.5rem;
  flex: auto;
}

.stepper:not(.stepper-vertical) .stepper-step:first-child .stepper-head {
  padding-left: 1.5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:last-child .stepper-head {
  padding-right: 1.5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:first-child) .stepper-head:before {
  height: 1px;
  width: 100%;
  content: "";
  background-color: #0000001a;
  flex: 1;
  margin-right: .5rem;
}

.stepper:not(.stepper-vertical) .stepper-step:not(:last-child) .stepper-head:after {
  height: 1px;
  width: 100%;
  content: "";
  background-color: #0000001a;
  flex: 1;
  margin-left: .5rem;
}

.stepper:not(.stepper-vertical) .stepper-head-icon {
  margin: 1.5rem .5rem 1.5rem 0;
}

.stepper.stepper-mobile {
  justify-content: center;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.stepper.stepper-mobile.stepper-progress-bar .stepper-head-icon {
  display: none;
}

.stepper.stepper-mobile .stepper-step {
  -webkit-flex: unset;
  -ms-flex: unset;
  flex: unset;
  height: fit-content;
  margin: 1rem 0;
}

.stepper.stepper-mobile .stepper-step:not(:last-child) .stepper-head:after {
  margin-left: 0;
}

.stepper.stepper-mobile .stepper-step:not(:first-child) .stepper-head:before {
  margin-right: 0;
}

.stepper.stepper-mobile .stepper-step:not(:last-child):not(:first-child) .stepper-head {
  padding-left: .25rem;
  padding-right: .25rem;
}

.stepper.stepper-mobile .stepper-head-icon {
  height: .5rem;
  width: .5rem;
  z-index: 1;
  margin: 0;
  font-size: 0;
}

.stepper.stepper-mobile .stepper-head-text {
  display: none;
}

.stepper.stepper-mobile .stepper-content {
  top: 2.56rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }

  .form-switch .form-check-input {
    transition: none;
  }

  .form-range::-webkit-slider-thumb {
    transition: none;
  }

  .form-range::-moz-range-thumb {
    transition: none;
  }

  .form-floating > label, .fade, .collapsing, .collapsing.collapse-horizontal, .accordion-button:after, .modal.fade .modal-dialog, .carousel-item, .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end, .carousel-control-prev, .carousel-control-next, .carousel-indicators [data-bs-target] {
    transition: none;
  }

  .spinner-border, .spinner-grow {
    animation-duration: 1.5s;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler, .navbar-expand-sm .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-sm .offcanvas-top, .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-sm .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }

  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler, .navbar-expand-md .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-md .offcanvas-top, .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-md .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-lg .offcanvas-top, .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-lg .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .modal-lg, .modal-xl {
    max-width: 800px;
  }

  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler, .navbar-expand-xl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xl .offcanvas-top, .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xl .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .modal-xl {
    max-width: 1140px;
  }

  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    -ms-flex-basis: auto;
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler, .navbar-expand-xxl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: inherit;
    z-index: 1000;
    -ms-flex-grow: 1;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xxl .offcanvas-top, .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }

  .navbar-expand-xxl .offcanvas-body {
    -ms-flex-grow: 0;
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }

  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

@media (prefers-reduced-motion) {
  .animation {
    transition: none !important;
    animation: unset !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 820px) and (orientation: landscape) {
  .datepicker-modal-container .datepicker-header {
    height: 100%;
  }

  .datepicker-modal-container .datepicker-date {
    margin-top: 100px;
  }

  .datepicker-modal-container .datepicker-day-cell {
    width: 32x;
    height: 32x;
  }

  .datepicker-modal-container {
    width: 475px;
    height: 360px;
    flex-direction: row;
  }

  .datepicker-modal-container.datepicker-day-cell {
    width: 36px;
    height: 36px;
  }
}

@media screen and (min-width: 320px) and (max-width: 825px) and (orientation: landscape) {
  .timepicker-elements {
    min-width: auto;
    min-height: auto;
    border-bottom-left-radius: .5rem;
    overflow-y: auto;
    flex-direction: row !important;
  }

  .timepicker-head {
    height: auto;
    min-height: 305px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px;
    padding-right: 10px !important;
  }

  .timepicker-head-content {
    flex-direction: column;
  }

  .timepicker-mode-wrapper {
    -webkit-box-pack: space-around !important;
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: space-around !important;
    flex-direction: row !important;
    justify-content: space-around !important;
  }

  .timepicker-current, .timepicker-dot {
    font-size: 3rem;
    font-weight: 400;
  }
}

@keyframes _spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes _fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes _fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes _fade-in-down {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-in-left {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-in-right {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _fade-out-down {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes _fade-out-left {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes _fade-out-right {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes _fade-out-up {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes _slide-in-down {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-in-left {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-in-right {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-in-up {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes _slide-out-down {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes _slide-out-left {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes _slide-out-right {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes _slide-out-up {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes _slide-down {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes _slide-left {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes _slide-right {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes _slide-up {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes _zoom-in {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes _zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

@keyframes _tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  70% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes _pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes _show-up-clock {
  0% {
    opacity: 0;
    transform: scale(.7);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.bottom-5 {
  bottom: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.order-2 {
  order: 2;
}

.order-1 {
  order: 1;
}

.order-3 {
  order: 3;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-12 {
  height: 3rem;
}

.h-4 {
  height: 1rem;
}

.h-8 {
  height: 2rem;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-12 {
  width: 3rem;
}

.w-px {
  width: 1px;
}

.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border-0 {
  border-width: 0;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-transparent {
  background-color: #0000;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity) );
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity) );
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity) );
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-4 {
  padding: 1rem;
}

.p-0 {
  padding: 0;
}

.p-6 {
  padding: 1.5rem;
}

.p-3 {
  padding: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.pl-0 {
  padding-left: 0;
}

.pb-2 {
  padding-bottom: .5rem;
}

.text-center {
  text-align: center;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-medium {
  font-weight: 500;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity) );
}

.opacity-50 {
  opacity: .5;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-150 {
  transition-duration: .15s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

body {
  background-color: #fafafa;
}

.nav-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.nav-link {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.form-floating > textarea.form-control {
  height: auto;
}

.section-title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 2.5rem;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1.25;
}

.product-card {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  border-width: 1px;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.product-card:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 1024px) {
  .product-card {
    margin-bottom: 0;
  }
}

.product-card > div {
  border-radius: .5rem;
}

.product-card img {
  margin: auto;
}

@media (min-width: 768px) {
  .product-header {
    grid-area: span 2 / span 2 / span 2 / span 2;
  }
}

h2.product-title {
  text-align: center;
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
}

.carousel-caption {
  max-width: 32rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity) );
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:outline-none:hover {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity) );
}

.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.focus\:bg-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity) );
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity) );
}

.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity) );
}

.focus\:no-underline:focus {
  text-decoration-line: none;
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.active\:bg-blue-800:active {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity) );
}

.active\:shadow-lg:active {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:block {
    display: block;
  }

  .md\:max-w-\[250px\] {
    max-width: 250px;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:gap-x-0 {
    column-gap: 0;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:-mr-14 {
    margin-right: -3.5rem;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:text-left {
    text-align: left;
  }
}

@media (min-width: 1280px) {
  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

/*# sourceMappingURL=index.bef7c828.css.map */
