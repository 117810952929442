@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: hsl(0, 0%, 98%);
}
.nav-item {
  @apply py-4;
}
.nav-link {
  @apply py-2;
}
.form-floating > textarea.form-control {
  @apply h-auto;
}
.section-title {
  @apply text-center font-medium leading-tight text-4xl mt-0 mb-10;
}
.product-card {
  @apply bg-white block mb-6 lg:mb-0 border rounded-lg hover:shadow-lg transition-all;
}
.product-card > div {
  @apply rounded-lg;
}
.product-card img {
  @apply m-auto;
}
.product-header {
  @apply md:col-span-2 md:row-span-2;
}
h2.product-title {
  @apply text-xl font-medium mb-4 text-center leading-tight drop-shadow-lg;
}
.carousel-caption {
  @apply max-w-lg mx-auto mb-10;
}
